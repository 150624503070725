<footer>
  <div [@animateFooter]>
    <ul class="footer-left-bar d-none d-md-block">
      <li>
        <a
          (click)="
            analyticsService.sendAnalyticEvent(
              'click_github',
              'footer',
              'github'
            )
          "
          href="https://github.com/thuptit"
          target="_blank"
        >
          <i class="fab fa-github"></i>
        </a>
      </li>
      <li>
        <a
          (click)="
            analyticsService.sendAnalyticEvent(
              'click_linkedin',
              'footer',
              'github'
            )
          "
          href="https://www.linkedin.com/in/thu-nguyen-van-506541217"
          target="_blank"
        >
          <i class="fab fa-linkedin-in"></i>
        </a>
      </li>
    </ul>
    <div class="footer-right-bar d-none d-md-block">
      <a
        (click)="
          analyticsService.sendAnalyticEvent(
            'click_send_mail',
            'footer',
            'email'
          )
        "
        href="mailto:thuitpti@gmail.com"
        target="_blank"
        >thuitptit@gmail.com
      </a>
    </div>
  </div>

  <div class="footer-credits text-center">
    <a
      (click)="
        analyticsService.sendAnalyticEvent(
          'click_github_portfolio_andresjose',
          'footer',
          'click'
        )
      "
      href="https://github.com/thuptit/thunv-portfolio"
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      <div>Built with Angular by Nguyễn Văn Thử</div>
    </a>
    <a
      (click)="
        analyticsService.sendAnalyticEvent(
          'click_github_portfolio_brittany',
          'footer',
          'click'
        )
      "
      href="https://github.com/bchiang7/v4"
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      <div class="mt-2">Designed by Brittany Chiang</div>
    </a>
  </div>
</footer>
